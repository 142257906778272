import React, { useEffect } from 'react';
import CurrencyFormat from '../../core/CurrencyFormat';
import { RiAddFill, RiSubtractFill, RiShoppingCart2Line } from 'react-icons/ri';

const SaleItems = props => {
  
    useEffect(() => {
        props.focusBarCode();
    }, [props]);

    const addItem = (id) => {
        let findItem = props.sale.find(t => t.id === id);
        findItem.quantity = parseFloat(findItem.quantity) + 1;
        findItem.subtotal = findItem.sellingRate * findItem.quantity;
        props.onSetSale(props.sale.map(a => a.id === id ? { ...a, findItem } : a));
        props.focusBarCode();
    }

    const removeItem = (id) => {
        let findItem = props.sale.find(t => t.id === id);
        findItem.quantity = parseFloat(findItem.quantity) - 1;
        findItem.subtotal = findItem.sellingRate * findItem.quantity;

        if (findItem.quantity <= 0) {
            props.onSetSale(props.sale.filter(a => a.id !== id));
        } else {
            props.onSetSale(props.sale.map(a => a.id === id ? { ...a, findItem } : a));
        }
        props.focusBarCode();
    }

    function editQuantity(event) {
        props.focusBarCode();
    }

    return (
        <div className="block bg-white rounded-xl overflow-hidden shadow mt-4" style={{ minHeight: '500px'}}>
            <table className="w-full">
                <thead>
                    <tr className="border-b">
                        <th className="text-md font-semibold px-6 py-3"></th>
                        <th className="text-md font-semibold px-6 py-3">Item</th>
                        <th className="text-md text-center font-semibold px-6 py-3">Precio</th>
                        <th className="text-md text-center font-semibold px-6 py-3">Cantidad</th>
                        <th className="text-md text-end font-semibold px-6 py-3">Subtotal</th>
                    </tr>
                </thead>
                <tbody>
                {props.sale.map((item, index) => {
                    return (
                        <tr className="border-b" key={index}>
                            <td className="text-md font-semibold px-4 py-4">
                                <div className="inline-flex">
                                    <button onClick={() => removeItem(item.id)} className="bg-gray-500 text-white hover:bg-gray-400 active:bg-gray-600 py-3 px-4 rounded-l-lg transition">
                                        <RiSubtractFill />
                                    </button>
                                    <button onClick={() => addItem(item.id)} className="bg-gray-500 text-white hover:bg-gray-400 active:bg-gray-600 py-3 px-4 rounded-r-lg transition">
                                        <RiAddFill />
                                    </button>
                                </div>
                            </td>
                            <td className="text-md px-6 py-2">{item.name}</td>
                            <td className="text-md text-center px-6 py-2">{CurrencyFormat(item.sellingRate)}</td>
                            <td className="text-md text-center px-6 py-2">
                                <input type="text" value={item.quantity} onChange={editQuantity} className="w-10 px-2.5 text-center mr-1" /> 
                                {item.sellingUnit.shortname}
                            </td>
                            <td className="text-md text-end px-6 py-2">{CurrencyFormat(item.subtotal)}</td>
                        </tr>
                    )
                })}
                </tbody>
            </table>
            {props.sale.length === 0 ? (<div className="flex items-center align-middle">
                <div className="w-full text-center pt-40">
                    <RiShoppingCart2Line className="text-6xl text-gray-300 mx-auto mb-3" />
                    <span className="text-gray-300 text-xl">Carro vacío</span>
                </div>
            </div>) : ('')}
        </div>
    );
}

export default SaleItems;