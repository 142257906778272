import React, { useEffect, useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { SessionContext } from '../../contexts/SessionContext';
import SessionService from '../../services/SessionService';
import BranchService from '../../services/BranchService';
import Logo from '../../../assets/images/groove-logo-login.svg';
import * as Notify from '../noty/Noty';

const SetCompany = () => {

    const { companies } = useContext(SessionContext);
    const [branches, setBranches] = useState([]);
    const [showCompany, setShowCompany] = useState(true);
    const [currentSession, setCurrentSession] = useState({});
    const navigate = useNavigate();

    useEffect(() => {
        if (companies.length === 0)
            navigate('/login');
    }, []);

    const configureCompany = async (id) => {
        const response = await SessionService.setCompany(id);
        if (!response.status) {
            Notify.clearAll();
            Notify.send('Hubo un problema al seleccionar la compañia. Intenta nuevamente', 'error');
        }
        const { token } = response.data;        
        localStorage.setItem('pos_token', token);        
        const data = SessionService.deserialize(token);

        // check if exists previous session
        const sessionReponse = await SessionService.getUserSession(data.userid).catch(e => { throw e });
        if (sessionReponse.data === '') {
            // create new session
            const branchResponse = await BranchService.getAll();
            if (branchResponse.data.length === 1) {                
                const session = {
                    branchId: branchResponse.data[0].id,
                    defaultLocationId: branchResponse.data[0].locations[0].id,
                    selectedPrinter: null
                }
                const sessResponse = await SessionService.saveSession(session);
                localStorage.setItem('pos_session', sessResponse.data.id); 
                localStorage.setItem('pos_branch', session.branchId); 
                localStorage.setItem('pos_location', session.defaultLocationId); 
                navigate('/cashier');
                
            } else {
                setBranches(branchResponse.data);
                setShowCompany(false);
            }
        } else {
            localStorage.setItem('pos_session', sessionReponse.data.id);
            localStorage.setItem('pos_branch', sessionReponse.data.branchId);
            localStorage.setItem('pos_location', sessionReponse.data.defaultLocationId); 
            navigate('/cashier');
        }
    }

    const configureCompanyBranch = async (id) => {
        const selectedBranch = branches.find(t => t.id === id);
        const session = {
            branchId: id,
            defaultLocationId: selectedBranch.locations[0].id,
            selectedPrinter: null
        }
        const sessResponse = await SessionService.saveSession(session);
        localStorage.setItem('pos_session', sessResponse.data.id); 
        localStorage.setItem('pos_branch', session.branchId); 
        localStorage.setItem('pos_location', session.data.defaultLocationId);
        navigate('/cashier');
    }

    const showSelectCompany = (show) => {
        if (show)
        return (
            <React.Fragment>
                <h1 className="text-2xl font-semibold text-center my-6">Selecciona Empresa</h1>
                <div className="divide-y divide-gray-200">
                    <div className="py-8 text-base leading-6 space-y-4 text-gray-700 sm:text-lg sm:leading-7">
                    <p className="text-sm text-center mb-4">Selecciona empresa con la que deseas trabajar</p>
                    {companies.map((company, index) => {
                        return (
                            <button 
                            key={index}
                            type="button"
                            className="text-left
                                px-4
                                py-2
                                border-b border-gray-200
                                w-full
                                hover:bg-gray-50
                                focus:outline-none focus:ring-0 focus:bg-gray-100 
                                transition
                                duration-300
                                cursor-pointer" 
                            onClick={() => configureCompany(company.id)}
                        >
                            <span className="font-semibold block">{company.name}</span>
                            <span className="text-sm text-gray-500 block">{company.businessName}</span>
                            <span className="text-sm text-gray-500">{company.taxId}</span>
                        </button>
                        )
                    })}
                    </div>
                </div>
            </React.Fragment>
        );
    }

    const showSelectBranch = (show) => {
        if (!show)
        return (
            <React.Fragment>
                <h1 className="text-2xl font-semibold text-center my-6">Selecciona Sucursal</h1>
                <div className="divide-y divide-gray-200">
                    <div className="py-8 text-base leading-6 space-y-4 text-gray-700 sm:text-lg sm:leading-7">
                    <p className="text-sm text-center mb-4">Selecciona sucursal con la que deseas trabajar</p>
                    {branches.map((branch, index) => {
                        return (
                            <button 
                            key={index}
                            type="button"
                            className="text-left
                                px-4
                                py-2
                                border-b border-gray-200
                                w-full
                                hover:bg-gray-50
                                focus:outline-none focus:ring-0 focus:bg-gray-100 
                                transition
                                duration-300
                                cursor-pointer" 
                            onClick={() => configureCompanyBranch(branch.id)}
                        >
                            <span className="font-semibold block">{branch.name}</span>
                            <span className="text-sm text-gray-500 block">{branch.address}</span>
                        </button>
                        )
                    })}
                    </div>
                </div>
            </React.Fragment>
        );
    }

    return (
        <React.Fragment>
            <div className="min-h-screen bg-gray-100 py-6 flex flex-col justify-center sm:py-12">
                <div className="relative py-3 sm:max-w-xl sm:mx-auto">
                    <div className="relative px-4 py-10 bg-white shadow-lg sm:rounded-3xl sm:p-20">
                        <div className="max-w-md mx-auto">
                            <div className="flex align-middle justify-center items-center">
                                <img src={Logo} className="block h-8" />                                
                            </div>
                            {showSelectCompany(showCompany)}
                            {showSelectBranch(showCompany)}
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}

export default SetCompany;