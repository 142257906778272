import API from '../core/HttpCommon';

const login = async (email, pass) => {
    return await API.post(`auth?email=${email}&password=${pass}`);
}

const setCompany = async (companyId) => {
    return await API.post(`auth/company/${companyId}`);
}

const getSession = async (id) => {
    return await API.get(`pos/session/${id}`);
}

const saveSession = async (data) => {
    return await API.post(`pos/session`, data);
}

const getUserSession = async (id) => {
    return await API.get(`pos/session/user/${id}`);
}

const getStatus = async (id) => {
    return await API.get(`pos/session/status/${id}`);
}

const saveMovement = async (data) => {
    return await API.post(`pos/movement/cash`, data);
}

const closeSession = async (id) => {
    return await API.post(`pos/session/close/${id}`, null);
}

const deserialize = (data) => {    
    var base64Url = data.split('.')[1];
    var base64 = base64Url?.replace('-', '+').replace('_', '/');
    const deserializedData = JSON.parse(window.atob(String(base64)));
    return deserializedData;
}

export default { getSession, getUserSession, saveSession, getStatus, saveMovement, closeSession, login, setCompany, deserialize }
