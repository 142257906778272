import Noty from 'noty';
import './Noty.css'

export const send = (text, type) => {
    new Noty({
        layout: 'bottom',
        text: text,
        type: type,
        timeout: 7000
    }).show();
}

export const clearAll = () => {
    Noty.closeAll()
}