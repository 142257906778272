import React, { useEffect, useState, useRef } from 'react';
import { RiBarcodeLine, RiRefreshLine } from 'react-icons/ri';
import ItemService from '../../services/ItemService';
import * as Notify from '../noty/Noty';

const BarcodeSearcher = props => {

    const inputRef = useRef(null);
    const [searching, setSearching] = useState(false);
    const [searchValue, setSearchValue] = useState('');

    useEffect(() => {
        focusInputBar();
    });

    const focusInputBar = () => {
        inputRef.current.focus();
    }

    const handleInputChange = (event) => {
        const { value } = event.target;
        setSearchValue(value);
    }

    const handleKeyUp = async (event) => {
        const { value } = event.target;
        if (value.length > 0) {
            if (event.keyCode === 13) { 
                await searchProduct(value);                    
            }
        }
    }

    const searchProduct = async (value) => {
        setSearching(true);
        try {
            const response = await ItemService.getByBarcode(value, 'EAN13');
            if (response.status) {
                const { id, sellingRate, name, isBillable, isExempt, sellingUnit, enabled } = response.data;
                let item = { id, sellingRate, name, quantity: 1, isBillable, isExempt, sellingUnit, subtotal: sellingRate, enabled };
                if (props.sale.some(e => e.id === item.id)) {
                    let findItem = props.sale.find(t => t.id === item.id);
                    findItem.quantity += 1;
                    findItem.subtotal = findItem.sellingRate * findItem.quantity;
                    props.onSetSale(props.sale.map(a => a.id === item.id ? { ...a, findItem } : a));
                } else {                
                    props.onSetSale([...props.sale, item]);
                }            
            }
        }
        catch(err) {
            Notify.clearAll();
            Notify.send(`El código ${value} no existe registrado como producto`, 'warning');
            console.error(err);
        }
        finally {
            setSearchValue('');
            setSearching(false);
            focusInputBar();
        }
    }
    
    return (
        <div className="flex flex-row relative">
            <button type="button" className="absolute left-5 top-2.5 px-2 py-2 rounded-full bg-purple-500 text-white">
                {!searching ?  <RiBarcodeLine className="text-xl" /> : ('')}
                {searching ? <RiRefreshLine className="text-xl rotate" /> : ('')}
            </button>
            <input 
                type="text" 
                ref={inputRef} 
                autoFocus={true} 
                className="bg-white rounded-xl shadow text-lg full w-full h-14 py-4 pl-16 
                transition-shadow focus:shadow-xl focus:outline-none disabled:opacity-80 disabled:pointer-events-none" 
                placeholder="Leer código de barras..." 
                onKeyUp={handleKeyUp} 
                onChange={handleInputChange} 
                value={searchValue} 
                disabled={searching} 
            />
        </div>
    );
}

export default BarcodeSearcher;