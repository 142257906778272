import {  useState } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { SessionContext } from './app/contexts/SessionContext';

import Login from './app/components/authentication/Login';
import SetCompany from './app/components/authentication/SetCompany';
import Pos from './app/components/pos/Pos';
import Cashier from './app/components/cashier/Cashier';
import Movements from './app/components/movements/Movements';
import CashManagement from './app/components/cash-management/CashManagement';

const App = () => {

	const [companies, setCompanies] = useState([]);

	return (		
		<SessionContext.Provider value={{ companies, setCompanies }}>
			<BrowserRouter>
				<Routes>
					<Route path="login" element={<Login />} />
					<Route path="set-company" element={<SetCompany companies={companies} />} />
					<Route path="/" element={<Pos />}>
						<Route path="cashier" element={<Cashier />} />
						<Route path="movements" element={<Movements/>} />
						<Route path="cash-management" element={<CashManagement />} />
					</Route>
				</Routes>
			</BrowserRouter>
		</SessionContext.Provider>
	)
}

export default App;