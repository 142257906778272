import API from '../core/HttpCommon';

const getAll = async () => {
    return await API.get(`payment-methods`);
}

const save = async (data) => {
    return await API.post(`pos/sale`, data);
}


export default { getAll, save }