import API from '../core/HttpCommon';

const getAll = async () => {
    return await API.post(`items`);
}

const findBy = async (value) => {
    return await API.get(`items/filter?q=` + value);
}

const getByBarcode = async (barcode, type) => {
    const resp = await API.get(`items/barcode/${barcode}/${type}`);
    return resp;
}

export default { getAll, findBy, getByBarcode }
