import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Outlet } from 'react-router';
import { Link } from 'react-router-dom';
import SessionService from '../../services/SessionService';
import {
    RiArrowLeftRightLine, 
    RiCalculatorLine,
    RiMoneyDollarBoxLine 
} from 'react-icons/ri';
import Logo from '../../../assets/images/groove-solo.png';

const Pos = () => {

    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const token = localStorage.getItem('pos_token');        
        const data = SessionService.deserialize(token);
        const isValid = data.exp * 1000 < Date.now() ? false : true;
        if (!isValid)    
            navigate('/login');
    }, []);

    return (
        <div className="flex flex-row h-screen antialiased bg-zinc-100">
            
            <div className="flex flex-row w-auto flex-shrink-0 pl-4 pr-2 py-4">
                <div className="flex flex-col items-center py-4 flex-shrink-0 w-20 bg-purple-600 shadow rounded-xl">
                    <div className="flex items-center justify-center h-12 w-12 bg-violet-50 rounded-full">
                        <img src={Logo} className="w-full p-2" />
                    </div>
                    <ul className="flex flex-col space-y-2 mt-12">
                        <li>
                            <Link to="/cashier" className="flex items-center">
                                <span className="flex items-center justify-center h-12 w-12 rounded-xl bg-purple-400 shadow-lg text-white transition-all">
                                    <RiCalculatorLine className="h-6 w-6" />
                                </span>                                
                            </Link>
                        </li>
                        <li>
                            <Link to="/movements" className="flex items-center">
                                <span className="flex items-center justify-center h-12 w-12 rounded-xl hover:bg-purple-500 hover:shadow-lg text-white transition-all">
                                    <RiArrowLeftRightLine className="h-6 w-6" />
                                </span>                                
                            </Link>
                        </li>
                        <li>
                            <Link to="/cash-management" className="flex items-center">
                                <span className="flex items-center justify-center h-12 w-12 rounded-xl hover:bg-purple-500 hover:shadow-lg text-white transition-all">
                                    <RiMoneyDollarBoxLine className="h-6 w-6" />
                                </span>                                
                            </Link>
                        </li>
                    </ul>
                </div>
            </div>            
            <Outlet />
        </div>
    );
}

export default Pos;