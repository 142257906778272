import React, { useEffect, useState, useContext } from 'react';
import { SessionContext } from '../../contexts/SessionContext';
import { useNavigate } from 'react-router-dom';
import SessionService from '../../services/SessionService';
import Logo from '../../../assets/images/groove-logo-login.svg';
import * as Notify from '../noty/Noty';

const Login = () => {

    const { setCompanies } = useContext(SessionContext);
    const [loading, setLoading] = useState(true);
    const [submitting, setSubmitting] = useState(false);
    const [inputs, setInputs] = useState({});

    const navigate = useNavigate();

    useEffect(() => {
        Notify.clearAll();
        localStorage.clear();
        setLoading(false);
    }, []);

    const handleInputChange = (event) => {
        setInputs({...inputs, [event.target.name]: event.target.value});        
    }

    const handleSubmit = async (event) => {
        event.preventDefault();
        setSubmitting(true);
        const { email, password } = inputs;
        if (typeof email === 'undefined' && typeof password === 'undefined') {
            Notify.send('Debes ingresar correo y contraseña válidos', 'error');
            setSubmitting(false);
            return false;
        }

        try {
            const response = await SessionService.login(email, password);
            if (!response.status) {
                Notify.send('Usuario y/o contraseña inválidos', 'error');
            } else {
                const { companies, token } = response.data;
                localStorage.setItem('pos_token', token);
                setCompanies(companies);
                navigate('/set-company');
            }
        }
        catch (error) {
            Notify.clearAll();
            Notify.send(error.response.data.message, 'error');
        }
        finally {
            setSubmitting(false);
        }
    }

    return (
        <React.Fragment>
            <div className="min-h-screen bg-gray-100 py-6 flex flex-col justify-center sm:py-12">
                <div className="relative py-3 sm:max-w-xl sm:mx-auto">
                    <div className="relative px-4 py-10 bg-white shadow-lg sm:rounded-3xl sm:p-20">
                        <div className="max-w-md mx-auto">
                            <div className="flex align-middle justify-center items-center">
                                <img src={Logo} className="block h-6" />                                
                            </div>
                            <h1 className="text-2xl font-semibold text-center my-6">Acceso POS</h1>
                            <p className="text-sm text-gray-600 text-center">Ingresa con tu usuario y contraseña</p>
                            <form className="divide-y divide-gray-200" onSubmit={handleSubmit}>
                                <div className="py-8 text-base leading-6 space-y-4 text-gray-700 sm:text-lg sm:leading-7">
                                    <div className="mb-2">
                                        <label htmlFor="password" className="text-gray-600 text-sm">Usuario</label>
                                        <input autoComplete="off" name="email" type="text" className="input-light" placeholder="E-mail" onChange={handleInputChange} autoFocus={true} disabled={submitting} />
                                    </div>
                                    <div className="mb-4">
                                        <label htmlFor="password" className="text-gray-600 text-sm">Contraseña</label>
                                        <input name="password" type="password" className="input-light" onChange={handleInputChange} placeholder="Contraseña" disabled={submitting} />
                                    </div>
                                    <div>
                                        <button type="submit" className="bg-violet-500 hover:bg-violet-400 active:bg-violet-600
                                            text-white text-sm rounded-md py-2 px-3 transiton w-full
                                            disabled:opacity-50 disabled:pointer-events-none" disabled={submitting}>Acceder</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}

export default Login;