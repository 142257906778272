import React, { useEffect, useState } from 'react';
import BarcodeSearcher from './BarcodeSearcher';
import SaleItems from './SaleItems';
import Totalizer from './Totalizer';
import ItemSearcher from './ItemSearcher';
import Pay from './Pay';
import PaymentService from '../../services/PaymentService';
import { LogLevel, HubConnectionBuilder } from '@microsoft/signalr';
import * as Notify from '../noty/Noty';
import { 
    RiSearch2Line,
    RiMoneyDollarCircleLine
} from 'react-icons/ri';

const Cashier = () => {

    const [loading, setLoading] = useState(true);
    const [currentSale, setCurrentSale] = useState([]);
    const [barFocus, setBarFocus] = useState(true);
    const [showItemSearcher, setShowItemSearcher] = useState(false);
    const [showPayment, setShowPayment] = useState(false);
    const [paymentDisabled, setPaymentDisabled] = useState(true);
    const [paymentMethods, setPaymentMethods] = useState([]);
    const [printConnection, setPrintConnection] = useState(null);


    useEffect(() => {
        async function fetchData() {
            const response = await PaymentService.getAll();
            setPaymentMethods(response.data);
            setLoading(false);
        }
        fetchData();

        /*const printHub = new HubConnectionBuilder()
                .withUrl('http://localhost:5000/print')
                .withAutomaticReconnect()
                .configureLogging(LogLevel.Debug)
                .build();
        setPrintConnection(printHub);*/

    }, []);

    /*useEffect(() => {
        if (printConnection) {
            printConnection.start()
                .then(result => {
                    console.log('Print Connected!');
                })
                .catch(e => console.log('Connection failed: ', e));
        }
    }, [printConnection]);*/

    async function getPrinters() {
        if (printConnection._connectionStarted) {
            try {
                console.log('Print order sended');
                //console.log(await printConnection.send('SendPrint', printMessage));
                console.log(await printConnection.send('GetPrinters'));
            }
            catch(e) {
                console.log(e);
            }
        } else {
            console.log('No connection to print server yet');
        }
    }

    async function print() {
        const printMessage = {
            documentName: 'asdsad',            
            taxId: '170034015',
            name: 'Gonzalo Fonseca', 
            businessName: 'basdsad',
            economicActivity: 'basdsad',
            address: 'basdsad',
            documentNumber: '432445',
            emissionDate: currentDateISOString(),
            totalAmount: '1000',
            totalNet: '1000',
            totalTax: '1000',
            totalExempt: '0',
            detail: [{ itemName: 'asdasd', quantity: '1', subtotal: '1000'}], //data.detail.map(det => ({ itemName: det.itemName, quantity: det.quantity.toString(), subtotal: det.subtotal.toString()}) ),
            stamp: ''
        }
        if (printConnection._connectionStarted) {
            try {
                console.log('Print order sended');
                //console.log(await printConnection.send('SendPrint', printMessage));
                console.log(await printConnection.send('SendPrint', 'POS80', '80', printMessage));
            }
            catch(e) {
                console.log(e);
            }
        } else {
            console.log('No connection to print server yet');
        }
    }

    function currentDateISOString () {
        const dateNow = new Date();
        return dateNow.getFullYear() +
        '-' + pad(dateNow.getMonth() + 1) +
        '-' + pad(dateNow.getDate()) +
        'T' + pad(dateNow.getHours()) +
        ':' + pad(dateNow.getMinutes()) +
        ':' + pad(dateNow.getSeconds()) +
        '.' + (dateNow.getMilliseconds() / 1000).toFixed(3).slice(2, 5) +
        'Z';
    }

    function pad(number) {
        if (number < 10)
            return '0' + number;

        return number;
    }

    function updateSaleState(val) {
        if (val.length > 0) {
            setPaymentDisabled(false)
        } else {
            setPaymentDisabled(true)
        }
        setCurrentSale(val);
    }

    function focusBarCode() {
        setBarFocus(true);
    }

    const showForm = () => {
        return (      
            <div className="flex-grow flex">
                <div className="flex flex-col h-full w-full p-4">
                    <BarcodeSearcher focus={barFocus} sale={currentSale} onSetSale={(val) => updateSaleState(val) } />
    
                    {/*<div className="gap-3 mt-4">
                        <button type="button" className="inline bg-white px-4 py-2 shadow rounded-lg">Venta 1</button>
                        <button type="button" className="inline bg-purple-500 text-white px-4 py-2 shadow rounded-lg"><RiAddFill /></button>
                    </div>*/}
    
                    <SaleItems sale={currentSale} onSetSale={(val) => updateSaleState(val)} focusBarCode={focusBarCode} />
                </div>
    
                <div className="w-4/12 flex flex-col h-full pr-4 pl-2 py-4">
                    <Totalizer sale={currentSale} />
                    <button 
                        type="button" 
                        className="flex items-center justify-center gap-2 bg-gray-500 text-white 
                                text-center px-4 py-2 rounded-lg text-md hover:bg-gray-400 active:bg-gray-600
                                transition-all mb-3"
                        onClick={() => setShowItemSearcher(true)}
                    >
                        <RiSearch2Line />
                        Buscar Productos
                    </button>
                    <button 
                        type="button" 
                        className="flex items-center justify-center gap-2 bg-purple-500 text-white 
                                text-center px-4 py-2 rounded-lg text-xl hover:bg-purple-400 active:bg-purple-600
                                transition-all disabled:opacity-50 disabled:pointer-events-none"
                        disabled={paymentDisabled}
                        onClick={() => setShowPayment(true)}
                    >
                        <RiMoneyDollarCircleLine />
                        Pagar
                    </button>
                </div>
    
                <ItemSearcher show={showItemSearcher} sale={currentSale} onSetSale={(val) => updateSaleState(val) } onHide={() => { setShowItemSearcher(false); focusBarCode(); }} />
                <Pay show={showPayment} sale={currentSale} onSetSale={(val) => updateSaleState(val) } paymentMethods={paymentMethods} onHide={() => { setShowPayment(false); focusBarCode(); }} />
            </div>
        );
    }

    return (
        loading ? 'Cargando...' : (showForm())
    )
}

export default Cashier;