import React, { useState, useEffect } from 'react';
import PaymentService from '../../services/PaymentService';
import CurrencyFormat from '../../core/CurrencyFormat';
import { RiCloseFill, RiMoneyDollarCircleLine } from 'react-icons/ri'; 
import * as te from 'tw-elements';
import * as Notify from '../noty/Noty';
import moment from 'moment';

let modal = null;
const currencyId = 'f0a9021a-f86e-4c0d-bd45-78e565b95e43';
const customer = {
    id: '62b8dd93-0196-4943-8a18-08082c09fac4',
    address: "SIN REGISTRAR",
    region: "SIN REGISTRAR",
    city: "SIN REGISTRAR",
    commune: "SIN REGISTRAR"
}
const Pay = props => {

    const [payments, setPayments] = useState([]);
    const [paymentAmount, setPaymentAmount] = useState(0);
    const [isLoading, setLoading] = useState(false);
    const sessionId = localStorage.getItem('pos_session');
    const branchId = localStorage.getItem('pos_branch');
    const locationId = localStorage.getItem('pos_location');

    useEffect(() => {
        modal = new te.Modal(document.getElementById('pay-modal'), { focus: true });
    }, [])

    useEffect(() => {
    }, [isLoading])

    useEffect(() => {        
        if (props.show) {
            modal.show();
        } else {
            modal.hide();
        }        
    }, [props.show]);

    const addDigit = (number) => {
        let current = paymentAmount.toString();
        const amount = current !== '0' ? current + number.toString() : number.toString();
        setPaymentAmount(amount);
    }

    const clearDigit = () => {
        setPaymentAmount(0);
    }

    const addMoney = (number) => {
        let current = paymentAmount.toString();
        const amount = current !== '0' ? (parseFloat(current) + number).toString() : number.toString();
        setPaymentAmount(amount);
    }

    const handleInputChange = (event) => {
        const { value } = event.target;
        setPaymentAmount(value);
    }

    function addPayment(id) {
        Notify.clearAll();  
        const totalToPay = props.sale.reduce((acc, item) => acc + parseFloat(item.subtotal), 0); 
             
        const balance = totalToPay - payments.reduce((acc, pay) => acc + pay.amount, 0);
        const method = props.paymentMethods.find(t => t.id === id);
        if (balance > 0) {
            let payAmount = 0;           
            if (method.id !== '285cbe43-5f70-4fdc-9fc1-ef3fe55e6620') {
                payAmount = parseFloat(balance);             
            } else {
                payAmount = parseFloat(paymentAmount);
                if (payAmount === 0) {
                    Notify.send('Debes ingresar monto a pagar en efectivo', 'warning');
                    return;
                }
            }

            let updatedPayments = [];
            const paymentIndex = payments.findIndex(t => t.paymentMethodId === id);
            if (paymentIndex > -1) {
                payments[paymentIndex].amount += payAmount;
                updatedPayments = [...payments];
            } else {
                const newPay = { paymentMethodId: id, currencyId, name: method.name, amount: payAmount, hasChange: method.hasChange };
                updatedPayments = [...payments, newPay];
            }
            const totalpaid = updatedPayments.reduce((acc, pay) => acc + pay.amount, 0);
            setPayments(updatedPayments);

            let currentPaymentAmount = (totalToPay - totalpaid) < 0 ? 0 : (totalToPay - totalpaid);
            setPaymentAmount(currentPaymentAmount);
        }
    }

    function removePayment(id) {
        const totalToPay = props.sale.reduce((acc, item) => acc + parseFloat(item.subtotal), 0);
        const updatedPayments = payments.filter(t => t.paymentMethodId !== id);
        const totalpaid = updatedPayments.reduce((acc, pay) => acc + pay.amount, 0);

        setPayments(updatedPayments);
        setPaymentAmount(totalToPay - totalpaid);
    }

    const explodeRate = (amount) => {
        var net = 0.0;
        var tax = 0.0;
        var pivot = 0.0;

        net = parseFloat(amount);
        tax = Math.round(net * 0.19);
        pivot = Math.round(net / 1.19);

        return [pivot, (net - pivot), net];
	}

    const finishPayment = () => {  
        Notify.clearAll();      
        setPayments([]);        
        setPaymentAmount(0);
        props.onSetSale([]);    
        props.onHide();
        //setLoading(false);
    }

    const cancelPayment = () => {
        props.onHide();
        setPayments([]);
        setPaymentAmount(0);
        //setLoading(false);
    }


    const confirmPayment = async (e) => {
        setLoading(true);
        /*const singleClick = parseInt(e.detail); 
        if (singleClick !== 1)
            return false;*/
            
        const totalToPay = props.sale.reduce((acc, item) => acc + parseFloat(item.subtotal), 0);
        const totalPaid = payments.reduce((acc, pay) => acc + pay.amount, 0);

        if (totalPaid >= totalToPay && totalPaid > 0 && totalToPay > 0) {
            const dateNow = moment().format('YYYY-MM-DD HH:mm:ss').replace(' ', 'T');            
            const paymentsWithChange = payments.map((pay) => {
                return {
                    paymentMethodId: pay.paymentMethodId,
                    currencyId: currencyId,
                    sessionPosId: sessionId,
                    methodName: pay.name,
                    amount: pay.hasChange ? (pay.amount - (totalPaid - totalToPay)) : pay.amount,
                    paymentDate: dateNow
                }
            });            

            let totalAmount = totalToPay;
            let totalNet = 0;
            let totalTax = 0;
            let totalExempt = 0;
            
            const salePosLines = props.sale.map(item => {
                const detailRate = !item.isExempt ? explodeRate(item.sellingRate) : [item.sellingRate, 0, item.sellingRate];
                totalNet += detailRate[0];
                totalTax += detailRate[1];
                return {
                    itemId: item.id,
                    itemName: item.name,
                    unitName: item.unitName,
                    itemSellingUnitId: '6e814eea-2328-41eb-a304-dcef04b105ad',
                    isExempt: item.isExempt,                        
                    taxRate: 19,
                    quantity: item.quantity,
                    sellingRate: detailRate[0],
                    subtotal: detailRate[0] * item.quantity,
                    taxAmount: detailRate[1],
                    grossRate: detailRate[2]
                }
            });

            const data = {
                customerId: customer.id,
                address: customer.address,
                region: customer.region,
                city: customer.city,
                commune: customer.commune,
                sessionPosId: sessionId,
                currencyId: currencyId,
                locationId: locationId,
                branchId: branchId,
                emissionDate: dateNow,
                totalAmount: totalAmount,
                totalPaid: totalPaid,
                totalNet: totalNet,
                totalTax: totalTax,
                totalExempt: totalExempt,
                TotalBalance: totalToPay -totalPaid,
                changeAmount: totalPaid - totalToPay,
                documentTypeId: 35,
                salePosLines: salePosLines,
                salePosPayments: paymentsWithChange
            }        
            PaymentService.save(data)
                .then(response => {   
                    setLoading(false);
                    finishPayment();
                    Notify.send(`Venta Finalizada! <strong>Vuelto: ${CurrencyFormat(data.changeAmount)}</strong>`, 'success');
                    //sendPrint(response.data);                    
                })
                .catch(e => {            
                    setLoading(false);
                    Notify.clearAll();    
                    Notify.send(e.message, 'error');                    
                })
        } else {
            setLoading(false);
            Notify.clearAll();    
            Notify.send('Debes ingresar monto y forma de pago', 'warning');
        }
    }

    return (
        <div 
            data-te-modal-init
            className="fixed left-0 top-0 z-[1055] hidden h-full w-full overflow-y-auto overflow-x-hidden outline-none"
            id="pay-modal"
            tabIndex={-1}
            data-te-backdrop="static"
            data-te-keyboard="false"
            aria-labelledby="pay-modal"
            aria-hidden="true">
            <div
                data-te-modal-dialog-ref
                className="pointer-events-none relative w-auto translate-y-[-50px] opacity-0 transition-all duration-300 ease-in-out min-[576px]:mx-auto min-[576px]:mt-7 min-[576px]:max-w-[500px] min-[992px]:max-w-[800px] min-[1200px]:max-w-[1140px]">
                <div
                    className="min-[576px]:shadow-[0_0.5rem_1rem_rgba(#000, 0.15)] pointer-events-auto relative flex w-full flex-col rounded-md border-none bg-white bg-clip-padding text-current shadow-lg outline-none">
                    <div
                        className="flex flex-shrink-0 items-center justify-between rounded-t-md border-b-2 border-neutral-100 border-opacity-100 p-4 dark:border-opacity-50">
                        <h5
                            className="text-xl font-medium leading-normal text-neutral-800"
                            id="pay-modal">
                            Transacción de Pago
                        </h5>
                        <button
                            onClick={() => {props.onHide()}}
                            type="button"
                            className="box-content rounded-none border-none hover:no-underline hover:opacity-75 focus:opacity-100 focus:shadow-none focus:outline-none"
                            aria-label="Close">
                            <RiCloseFill className="text-3xl" />
                        </button>
                    </div>
                    <div className="relative flex-auto p-4" data-te-modal-body-ref>
                        
                        <div className="grid grid-flow-col">
                            <div className="">
                                <table className="w-full">
                                    <tbody>
                                        {payments.map((pay, index) =>
                                            <tr key={index} className="">
                                                <td><button onClick={() => removePayment(pay.paymentMethodId)} 
                                                type="button" 
                                                className="block bg-gray-200 text-gray-700 
                                                text-center px-4 py-2 rounded-lg text-md hover:bg-gray-300 active:bg-gray-400
                                                transition-all mb-3 disabled:pointer-events-none" 
                                                disabled={isLoading}
                                            >
                                                    <RiCloseFill />
                                                </button>
                                                </td>
                                                <td>{pay.name}</td>
                                                <td>{CurrencyFormat(pay.amount)}</td>
                                            </tr>
                                        )}
                                    </tbody>
                                </table>
                            </div>
                            <div className="">
                                <input type="text" className="input-light text-xl text-end" onChange={handleInputChange} value={paymentAmount} />
                                <div className="grid grid-cols-2 mt-4">
                                    <div className="">
                                        <div className="grid grid-cols-3 gap-2">
                                            <button type="button" className="border rounded-lg px-4 py-6 hover:shadow hover:bg-gray-100 transition disabled:opacity-50 disabled:pointer-events-none" onClick={() => addDigit(7)}>7</button>
                                            <button type="button" className="border rounded-lg px-4 py-6 hover:shadow hover:bg-gray-100 transition disabled:opacity-50 disabled:pointer-events-none" onClick={() => addDigit(8)}>8</button>
                                            <button type="button" className="border rounded-lg px-4 py-6 hover:shadow hover:bg-gray-100 transition disabled:opacity-50 disabled:pointer-events-none" onClick={() => addDigit(9)}>9</button>
                                            <button type="button" className="border rounded-lg px-4 py-6 hover:shadow hover:bg-gray-100 transition disabled:opacity-50 disabled:pointer-events-none" onClick={() => addDigit(4)}>4</button>
                                            <button type="button" className="border rounded-lg px-4 py-6 hover:shadow hover:bg-gray-100 transition disabled:opacity-50 disabled:pointer-events-none" onClick={() => addDigit(5)}>5</button>
                                            <button type="button" className="border rounded-lg px-4 py-6 hover:shadow hover:bg-gray-100 transition disabled:opacity-50 disabled:pointer-events-none" onClick={() => addDigit(6)}>6</button>
                                            <button type="button" className="border rounded-lg px-4 py-6 hover:shadow hover:bg-gray-100 transition disabled:opacity-50 disabled:pointer-events-none" onClick={() => addDigit(1)}>1</button>
                                            <button type="button" className="border rounded-lg px-4 py-6 hover:shadow hover:bg-gray-100 transition disabled:opacity-50 disabled:pointer-events-none" onClick={() => addDigit(2)}>2</button>
                                            <button type="button" className="border rounded-lg px-4 py-6 hover:shadow hover:bg-gray-100 transition disabled:opacity-50 disabled:pointer-events-none" onClick={() => addDigit(3)}>3</button>
                                            <button type="button" className="border col-span-2 rounded-lg px-4 py-6 hover:shadow hover:bg-gray-100 transition disabled:opacity-50 disabled:pointer-events-none" onClick={() => addDigit(0)}>0</button>
                                            <button type="button" className="border col-span-1 rounded-lg px-4 py-6 hover:shadow hover:bg-gray-100 transition disabled:opacity-50 disabled:pointer-events-none" onClick={() => clearDigit()}>C</button>
                                        </div>
                                    </div>
                                    <div className="grid grid-cols-1 gap-2 pl-4">
                                        {props.paymentMethods.map((pay, index) => 
                                            <button key={index} onClick={() => addPayment(pay.id)} className="block bg-gray-500 text-white 
                                            text-center px-4 py-3 rounded-lg text-md hover:bg-gray-400 active:bg-gray-600
                                            transition-all mb-2 disabled:pointer-events-none" type="button" disabled={isLoading}>
                                            {pay.name}
                                        </button>)}
                                        <br />
                                        <button type="button" className="block bg-gray-200 text-gray-700 
                                            text-center px-4 py-2 rounded-lg text-md hover:bg-gray-300 active:bg-gray-400
                                            transition-all disabled:opacity-50 disabled:pointer-events-none" onClick={() => addMoney(1000)} disabled={isLoading}>$ 1.000</button>
                                        <button type="button" className="block bg-gray-200 text-gray-700  
                                            text-center px-4 py-2 rounded-lg text-md hover:bg-gray-300 active:bg-gray-400
                                            transition-all disabled:opacity-50 disabled:pointer-events-none" onClick={() => addMoney(5000)} disabled={isLoading}>$ 5.000</button>
                                        <button type="button" className="block bg-gray-200 text-gray-700  
                                            text-center px-4 py-2 rounded-lg text-md hover:bg-gray-300 active:bg-gray-400
                                            transition-all disabled:opacity-50 disabled:pointer-events-none" onClick={() => addMoney(10000)} disabled={isLoading}>$ 10.000</button>

                                    </div>
                                </div>                                
                            </div>
                        </div>                        
                    </div>
                    <div className="flex gap-3 flex-wrap items-center justify-end rounded-b-md border-t-2 border-neutral-100 border-opacity-100 p-4 dark:border-opacity-50">
                        <button 
                            type="button" 
                            className="flex items-center justify-center gap-2 bg-gray-500 text-white 
                            text-center px-4 py-2 rounded-lg text-md hover:bg-gray-400 active:bg-gray-600
                            transition-all mb-3 disabled:opacity-50 disabled:pointer-events-none"
                            disabled={isLoading}
                            onClick={() => cancelPayment()}
                        >
                            Cancelar
                        </button>
                        <button 
                            type="button" 
                            className="flex items-center justify-center gap-2 bg-green-500 text-white 
                            text-center px-4 py-2 rounded-lg text-md hover:bg-green-400 active:bg-green-600
                            transition-all mb-3 disabled:opacity-50 disabled:pointer-events-none"
                            disabled={isLoading}
                            onClick={() => confirmPayment()}
                        >
                            <RiMoneyDollarCircleLine />
                            Confirmar Pago
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Pay;