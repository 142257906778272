import React, { useState, useEffect } from 'react';
import CurrencyFormat from '../../core/CurrencyFormat';

const Totalizer = props => {

    const [subtotal, setSubtotal] = useState(0);
    const [discount, setDiscount] = useState(0);
    const [total, setTotal] = useState(0);

    useEffect(() => {
        const total = props.sale.map(line => line.subtotal).reduce((acc, a) => acc + a, 0);
        setSubtotal(total);       
        setTotal(total);
    }, [props.sale])

    return (
        <div className="block bg-white p-4 mb-4 rounded-xl shadow">
            <div className="grid grid-cols-2 mb-2">
                <div className="text-md font-semibold">Subtotal</div>
                <div className="text-md font-semibold text-end">{CurrencyFormat(subtotal)}</div>
            </div>
            <div className="grid grid-cols-2 mb-2">
                <div className="text-md font-semibold">Descuentos</div>
                <div className="text-md font-semibold text-end">{CurrencyFormat(discount)}</div>
            </div>
            <div className="grid grid-cols-2">
                <div className="text-xl font-semibold">Total</div>
                <div className="text-xl font-semibold text-end">{CurrencyFormat(total)}</div>
            </div>                    
        </div>
    )
}

export default Totalizer;