import React, { useState, useRef, useEffect } from 'react';
import CurrencyFormat from '../../core/CurrencyFormat';
import ItemService from '../../services/ItemService';
import { RiCloseFill, RiDatabase2Line, RiSearchLine } from 'react-icons/ri';
import * as Notify from '../noty/Noty';
import * as te from 'tw-elements';

let modal = null;

const ItemSearcher = props => {

    const inputRef = useRef(null);
    const [needle, setNeedle] = useState('');
    const [findedItems, setFindedItems] = useState([]);
    const [searching, setSearching] = useState(false);

    useEffect(() => {
        modal = new te.Modal(document.getElementById('item-searcher-modal'), { focus: true });
    }, [])

    useEffect(() => {        
        if (props.show) {
            modal.show();
            if (inputRef && inputRef.current) {
                focusInputBar();
            }    
        } else {
            modal.hide();
        }
        
    }, [props.show]);

    const focusInputBar = () => {
        inputRef.current.focus();
    }

    const handleInputChange = (event) => {
        const { value } = event.target;
        setNeedle(value);
    }

    const handleKeyUp = async (event) => {
        const { value } = event.target;
        if (value.length > 0) {
            if (event.keyCode === 13) {    
                await searchBy(value);
            }
        }
    }

    const searchBy = async (value) => {
        setSearching(true);
        const response = await ItemService.findBy(value);
        if (response.status !== 200) {

        } else {
            setFindedItems(response.data);
        }
        setSearching(false);
    }

    const selectItem = (id) => {
        const finded = findedItems.find(t => t.id === id);
        const { sellingRate, name, isBillable, isExempt, sellingUnit, enabled } = finded;
        let item = { id, sellingRate, name, quantity: 1, isBillable, isExempt, sellingUnit, subtotal: sellingRate, enabled };
        if (props.sale.some(e => e.id === item.id)) {
            let findItem = props.sale.find(t => t.id === item.id);
            findItem.quantity += 1;
            findItem.subtotal = findItem.sellingRate * findItem.quantity;
            props.onSetSale(props.sale.map(a => a.id === item.id ? { ...a, findItem } : a));
        } else {                
            props.onSetSale([...props.sale, item]);
        }
        Notify.clearAll();
        Notify.send(`Se ha agregado <strong>${item.name}</strong> a la venta`, 'success');
        focusInputBar();
    }

    return (
        <div 
            data-te-modal-init
            className="fixed left-0 top-0 z-[1055] hidden h-full w-full overflow-y-auto overflow-x-hidden outline-none"
            id="item-searcher-modal"
            tabIndex={-1}
            data-te-backdrop="static"
            data-te-keyboard="false"
            aria-labelledby="item-searcher-modal"
            aria-hidden="true">
            <div
                data-te-modal-dialog-ref
                className="pointer-events-none relative w-auto translate-y-[-50px] opacity-0 transition-all duration-300 ease-in-out min-[576px]:mx-auto min-[576px]:mt-7 min-[576px]:max-w-[500px] min-[992px]:max-w-[800px] min-[1200px]:max-w-[1140px]">
                <div
                    className="min-[576px]:shadow-[0_0.5rem_1rem_rgba(#000, 0.15)] pointer-events-auto relative flex w-full flex-col rounded-md border-none bg-white bg-clip-padding text-current shadow-lg outline-none">
                    <div
                        className="flex flex-shrink-0 items-center justify-between rounded-t-md border-b-2 border-neutral-100 border-opacity-100 p-4 dark:border-opacity-50">
                        <h5
                            className="text-xl font-medium leading-normal text-neutral-800"
                            id="item-searcher-modal">
                            Buscar Productos
                        </h5>
                        <button
                            onClick={() => {props.onHide()}}
                            type="button"
                            className="box-content rounded-none border-none hover:no-underline hover:opacity-75 focus:opacity-100 focus:shadow-none focus:outline-none"
                            aria-label="Close">
                            <RiCloseFill className="text-3xl" />
                        </button>
                    </div>
                    <div className="relative flex-auto p-4" data-te-modal-body-ref>
                        <div className="mb-3">
                            <div className="col-sm-10">
                                <input 
                                    type="text" 
                                    className="border w-full px-4 py-3 rounded-xl outline-none
                                            focus:border-violet-500" 
                                    ref={inputRef} 
                                    onKeyUp={handleKeyUp} 
                                    onChange={handleInputChange} 
                                    value={needle} 
                                    placeholder="Buscar por nombre, código, sku..."
                                />
                            </div>
                        </div>
                        <div style={{ minHeight: '400px' }}>
                            <table className="w-full">
                                <thead>
                                    <tr className="border-b">
                                        <th className="text-start text-md font-semibold px-6 py-3">Código</th>
                                        <th className="text-md font-semibold px-6 py-3">Nombre Item</th>
                                        <th className="text-start text-md font-semibold px-6 py-3">SKU</th>
                                        <th className="text-end text-md font-semibold px-6 py-3">Precio</th>
                                    </tr>
                                </thead>
                                <tbody>
                                {findedItems.map((item, index) => {
                                    return (
                                        <tr className="border-b hover:cursor-pointer hover:bg-gray-50 transition" key={index} onClick={() => selectItem(item.id)}>
                                            <td className="text-md px-6 py-4">{item.code}</td>
                                            <td className="text-md px-6 py-4">{item.name}</td>
                                            <td className="text-md px-6 py-4">{item.sku}</td>
                                            <td className="text-md text-end px-6 py-4">{CurrencyFormat(item.sellingRate)}</td>
                                        </tr>
                                    )
                                })}
                                </tbody>
                            </table>
                            {findedItems.length === 0 && !searching ? (<div className="flex items-center align-middle">
                                <div className="w-full text-center pt-40">
                                    <RiDatabase2Line className="text-6xl text-gray-300 mx-auto mb-3" />
                                    {findedItems.length === 0 && needle.length > 0 ? 
                                    <span className="text-gray-400">No hemos encontrado lo que buscabas :(</span> :
                                    <span className="text-gray-400">Busca productos en tu inventario</span> }
                                </div>                                
                            </div>) : ''}
                            {searching ? 
                                <div className="w-full text-center pt-40">
                                    <RiSearchLine className="text-4xl text-gray-300 mx-auto mb-3" />
                                    <span className="text-gray-400">Buscando...</span>
                                </div> :''}
                        </div>
                    </div>
                    <div className="flex flex-shrink-0 flex-wrap items-center justify-end rounded-b-md border-t-2 border-neutral-100 border-opacity-100 p-4 dark:border-opacity-50">
                        <button 
                            type="button" 
                            className="flex items-center justify-center gap-2 bg-gray-500 text-white 
                            text-center px-4 py-2 rounded-lg text-md hover:bg-gray-400 active:bg-gray-600
                            transition-all mb-3"
                            onClick={() => {props.onHide(); Notify.clearAll()}}
                        >
                            Cerrar
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ItemSearcher;